import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdLock, MdPerson } from 'react-icons/md';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import { Container, Welcome, Content } from './styles';

import logo from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

interface LoginFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { login } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: LoginFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await login({
          email: data.email,
          password: data.password,
        });

        history.push('/dashboard');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [login, addToast, history],
  );

  return (
    <Container>
      <Welcome>
        <h1>Olá! Seja bem-vindo novamente.</h1>
      </Welcome>

      <Content>
        <img src={logo} alt="Logo Educacione" />

        <Form ref={formRef} onSubmit={handleSubmit} noValidate>
          <Input
            icon={MdPerson}
            type="email"
            name="email"
            label="E-mail"
            placeholder="E-mail"
          />

          <Input
            icon={MdLock}
            type="password"
            name="password"
            label="Senha"
            placeholder="Senha"
          />

          <Button type="submit" color="primary" loading={loading}>
            Entrar
          </Button>
        </Form>

        <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
      </Content>
    </Container>
  );
};

export default Login;
