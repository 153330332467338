import styled from 'styled-components';
import { shade } from 'polished';

interface CoverProps {
  url: string;
}

export const Cover = styled.div<CoverProps>`
  min-height: 350px;
  width: 100%;
  background-image: ${({ url }) => `url(${url})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
`;

export const Background = styled.div`
  background: ${({ theme }) => theme.backgroundCard};

  > div {
    display: flex;
    align-items: center;

    > img {
      margin-right: auto;
      width: 159px;

      @media (max-width: 767px) {
        & {
          width: 112px;
        }
      }
    }

    > a {
      color: #ff1a66;
      font-weight: bold;
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-right: 40px;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.1, '#ff1a66')};
      }

      svg {
        margin-right: 8px;
      }

      @media (max-width: 991px) {
        & {
          display: none;
        }
      }
    }
  }
`;
