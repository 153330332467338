import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { MdClose, MdSearch } from 'react-icons/md';
import Axios from 'axios';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import TextArea from '../../../components/TextArea';
import Modal, { ModalHeader } from '../../../components/Modal';

interface SearchMovieFormData {
  search: string;
}

interface Movie {
  id: string;
  name: string;
  title: string;
  media_type: 'tv' | 'movie';
  poster_path: string;
}

interface MovieRegistrationFormData {
  name: string;
  category: string;
  description: string;
  exam_name: string;
}

const MovieRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [genres, setGenres] = useState([]);
  const [channels, setChannels] = useState([]);

  const [showMovieSearch, setShowMovieSearch] = useState(false);
  const [movies, setMovies] = useState<Movie[]>([]);

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    api.get('/genres/options').then(response => setGenres(response.data));
    api.get('/channels/options').then(response => setChannels(response.data));
  }, []);

  const searchMovies = useCallback(async (data: SearchMovieFormData) => {
    const response = await Axios.get(
      'https://api.themoviedb.org/3/search/multi',
      {
        params: {
          api_key: '16e1ba7d25c3dada13a581d0cf58de76',
          append_to_response: 'videos,images,person',
          language: 'pt-BR',
          query: data.search,
        },
      },
    );

    setMovies(response.data.results);
  }, []);

  const getMovieDetails = useCallback(
    async (id: string, media_type: 'tv' | 'movie') => {
      const response = await Axios.get(
        `https://api.themoviedb.org/3/${media_type}/${id}`,
        {
          params: {
            api_key: '16e1ba7d25c3dada13a581d0cf58de76',
            append_to_response: 'videos,images,person',
            language: 'pt-BR',
          },
        },
      );

      const foundedMovie = response.data;

      formRef.current?.setData({
        name: foundedMovie.title || foundedMovie.name,
        tagline: foundedMovie.tagline,
        duration: foundedMovie.runtime,
        year: foundedMovie.release_date.split('-')[0],
        cover: foundedMovie.poster_path,
        backdrop: foundedMovie.backdrop_path,
        description: foundedMovie.overview,
      });

      // formRef.current?.setFieldValue('duration', foundedMovie.runtime);

      setShowMovieSearch(false);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: MovieRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({});

        await schema.validate(data, { abortEarly: false });

        await api.post('/movies', data);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O filme foi cadastrado com sucesso!',
        });

        history.push('/filmes');
      } catch (err) {
        setLoading(false);

        console.log(err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar filme"
          items={[
            { title: 'Filmes', link: '/filmes' },
            { title: 'Cadastrar filme' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ flex: 1 }}>
                        <Input type="text" name="name" label="Nome" />
                      </div>
                      <Button
                        type="button"
                        color="primary"
                        style={{
                          marginLeft: '8px',
                          marginTop: '16px',
                          height: '50px',
                        }}
                        onClick={() => setShowMovieSearch(true)}
                      >
                        <MdSearch size={20} />
                      </Button>
                    </div>
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="tagline" label="Slogan" />
                  </Col>

                  <Col md={2}>
                    <Input name="duration" type="number" label="Duração" />
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <Select
                      name="age_group"
                      label="Classificação"
                      options={[
                        {
                          label: 'Livre',
                          value: 'L',
                        },
                        {
                          label: '10 anos',
                          value: '10',
                        },
                        {
                          label: '12 anos',
                          value: '12',
                        },
                        {
                          label: '14 anos',
                          value: '14',
                        },
                        {
                          label: '16 anos',
                          value: '16',
                        },
                        {
                          label: '18 anos',
                          value: '18',
                        },
                      ]}
                    />
                  </Col>

                  <Col md={2}>
                    <Input name="year" type="number" label="Ano" />
                  </Col>

                  <Col md={4}>
                    <Input name="cover" type="text" label="Capa" />
                  </Col>

                  <Col md={4}>
                    <Input name="backdrop" type="text" label="Backdrop" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Input name="trailer" type="url" label="Trailer" />
                  </Col>

                  <Col md={4}>
                    <Select
                      name="genre_ids"
                      label="Gêneros"
                      isMulti
                      options={genres}
                    />
                  </Col>

                  <Col md={4}>
                    <Select
                      name="channel_ids"
                      label="Canais"
                      isMulti
                      options={channels}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar filme
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={showMovieSearch}
        setIsOpen={() => setShowMovieSearch(!showMovieSearch)}
      >
        <ModalHeader>
          <div>Buscar filme</div>

          <button type="button" onClick={() => setShowMovieSearch(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form onSubmit={searchMovies}>
          <Input type="text" name="search" label="Buscar" />

          <Button type="submit" color="primary">
            Buscar filme
          </Button>
        </Form>

        {movies.length > 0 && (
          <>
            <hr
              style={{
                borderColor: '#ccc',
                borderStyle: 'solid',
                borderWidth: '1px',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            />

            <ul style={{ listStyle: 'none' }}>
              {movies.map(movie => (
                <li
                  key={movie.id}
                  style={{
                    background: '#ccc',
                    borderRadius: '4px',
                    padding: '8px',
                    marginTop: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {movie.poster_path ? (
                    <img
                      src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                      alt={movie.title || movie.name}
                      style={{ width: '32px', borderRadius: '4px' }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '32px',
                        height: '48px',
                        borderRadius: '4px',
                        background: '#000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      ?
                    </div>
                  )}

                  <span style={{ flex: 1, marginLeft: '8px' }}>
                    {movie.title || movie.name}
                  </span>

                  <button
                    type="button"
                    onClick={() => getMovieDetails(movie.id, movie.media_type)}
                  >
                    Selecionar
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </Modal>
    </>
  );
};

export default MovieRegistration;
