import React, { useState, useRef } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, SubMenu } from './styles';

interface MenuItemProps {
  title: string;
  link?: string;
  icon: React.ComponentType<IconBaseProps>;
  subitems?: Array<{
    title: string;
    link: string;
  }>;
}

const MobileMenuItem: React.FC<MenuItemProps> = ({
  link,
  title,
  icon: Icon,
  subitems,
}) => {
  const menuItemRef = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState(false);

  return (
    <Container
      active={active}
      ref={menuItemRef}
      onClick={() => setActive(!active)}
    >
      <li>
        {link ? (
          <Link to="/">
            {Icon && <Icon size={20} />}
            {title}
          </Link>
        ) : (
          <span>
            {Icon && <Icon size={20} />}
            {title}
          </span>
        )}

        {subitems && (
          <SubMenu active={active}>
            <ul>
              {subitems.map(subitem => (
                <li key={subitem.title}>
                  <Link to={subitem.link}>
                    <MdKeyboardArrowRight size={18} />
                    {subitem.title}
                  </Link>
                </li>
              ))}
            </ul>
          </SubMenu>
        )}
      </li>
    </Container>
  );
};

export default MobileMenuItem;
