import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { DarkModeProvider } from './theme';
// import { SocketProvider } from './socket';

const AppProvider: React.FC = ({ children }) => (
  <DarkModeProvider>
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  </DarkModeProvider>
);

export default AppProvider;
