import React from 'react';
import { Container } from 'styled-bootstrap-grid';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

const Dashboard: React.FC = () => {
  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Dashboard" items={[{ title: 'Dashboard' }]} />
      </Container>

      <Footer />
    </>
  );
};

export default Dashboard;
