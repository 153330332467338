import React, { useCallback, useRef, useState, useEffect } from 'react';
import { parseISO, format, subYears } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { MdEvent } from 'react-icons/md';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import ModalConfirm from '../../../components/Modal/Confirm';

import { Profile, StatusBadge, TransactionItem } from './styles';
import DatePicker from '../../../components/Datepicker';
import formatDate from '../../../utils/formatDate';

interface UserData {
  created_at: string;
  show_name: string;
  avatar_url: string;
  nickname: string;
}

interface UserPreviewFormData {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  cpf: string;
  birthday: Date;
  role: string;
}

interface TransactionData {
  payment_ext: string;
  amount: string;
  payment_method: 'pix' | 'boleto' | 'credit_card';
  start_date?: string;
  end_date?: string;
  warranty_date?: string;
  amout: string;
  status: string;
  status_info: {
    name: string;
    color: string;
    background: string;
  };
}

const payment_methods = {
  pix: 'Pix',
  boleto: 'Boleto bancário',
  credit_card: 'Cartão de crédito',
};

const StudentPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [userLoading, setStudentLoading] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState('');
  const [areDeleting, setAreDeleting] = useState(false);

  const [user, setStudent] = useState<UserData>({} as UserData);
  const [transactions, setTransactions] = useState<TransactionData[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setStudentLoading(true);

      try {
        const response = await api.get(`/users/${id}`);

        const created_at = format(
          parseISO(response.data.created_at),
          " MMMM 'de' yyyy",
          { locale: ptBR },
        );

        const price_per_wording = response.data.price_per_wording
          ? response.data.price_per_wording / 100
          : null;

        setStudent({ ...response.data, created_at, price_per_wording });

        formRef.current?.setData({
          birthday: response.data.birthday,
          phone_number: response.data.phone_number,
          cpf: response.data.cpf,
          role: response.data.role,
          account_type: response.data.account_type,
        });

        setStudentLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O usuário não foi encontrado!',
        });

        history.push('/alunos');
      }
    }

    loadData();
  }, [id, addToast, history]);

  useEffect(() => {
    async function loadData() {
      try {
        setTransactionsLoading(true);

        const response = await api.get(`/users/${id}/transactions`);

        setTransactions(response.data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O aluno não foi encontrado!',
        });
      } finally {
        setTransactionsLoading(false);
      }
    }

    loadData();
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: UserPreviewFormData) => {
      setStudentLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          phone_number: Yup.string()
            .min(14, 'Telefone inválido')
            .max(15, 'Telefone inválido')
            .required('Telefone obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .required('CPF obrigatório'),
          birthday: Yup.date()
            .max(subYears(new Date(), 14), 'Mínimo de 14 anos')
            .nullable(),
          role: Yup.string().required('Função obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`users/${id}`, {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          email: data.email,
          cpf: data.cpf,
          birthday: data.birthday,
          role: data.role,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O usuário foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setStudentLoading(false);
      }
    },
    [addToast, id],
  );

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);

      const response = await api.delete(`purchases/${selectedTransaction}`);

      setTransactions(oldTransactions =>
        oldTransactions.map(transaction => {
          if (transaction.payment_ext === selectedTransaction) {
            return response.data;
          }

          return transaction;
        }),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O evento foi excluído com sucesso!',
      });

      setAreDeleting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão.',
      });
    } finally {
      setDeleteLoading(false);
    }
  }, [selectedTransaction, addToast]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar usuário"
          items={[
            { title: 'Usuário', link: '/alunos' },
            { title: 'Visualizar usuário' },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card>
              {userLoading && <Loader />}

              <Profile>
                <img src={user.avatar_url} alt={user.show_name} />

                <h2>{user.show_name}</h2>

                <span>
                  Membro desde
                  {user.created_at}
                </span>
              </Profile>
            </Card>
          </Col>

          <Col md={8}>
            <Card>
              {userLoading && <Loader />}
              <h2>Dados pessoais</h2>

              <Form
                ref={formRef}
                initialData={user}
                onSubmit={handleSubmit}
                noValidate
              >
                <Row>
                  <Col md={6}>
                    <Input type="text" name="first_name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="last_name" label="Sobrenome" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input type="email" name="email" label="E-mail" />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      mask=""
                      maskChar={null}
                      type="text"
                      name="phone_number"
                      label="Telefone"
                      isPhone
                    />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                    />
                  </Col>

                  <Col md={4}>
                    <DatePicker
                      icon={MdEvent}
                      name="birthday"
                      label="Data de Nascimento"
                      maxDate={subYears(new Date(), 14)}
                    />
                  </Col>

                  <Col md={4}>
                    <Select
                      name="role"
                      label="Função"
                      options={[
                        { value: 'administrator', label: 'Administrador' },
                        { value: 'student', label: 'Aluno' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input
                      type="text"
                      name="nickname"
                      label="Nickname"
                      disabled
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      type="text"
                      name="language"
                      label="Língua Estrangeira"
                      disabled
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar usuário
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              {transactionsLoading && <Loader />}
              <h2>Transações</h2>

              {!transactions.length && <p>Nenhuma transação encontrada</p>}

              {transactions.map(transaction => (
                <TransactionItem key={transaction.payment_ext}>
                  <div>
                    <small>Código</small>
                    <span>{transaction.payment_ext}</span>
                  </div>

                  <div>
                    <small>Valor</small>
                    <span>{transaction.amount}</span>
                  </div>

                  <div>
                    <small>Forma de pgto</small>
                    <span>{payment_methods[transaction.payment_method]}</span>
                  </div>

                  <div>
                    <small>Confirmação do pgto</small>
                    <span>
                      {transaction.start_date
                        ? formatDate(transaction.start_date, 'dd/MM/yyyy')
                        : '-'}
                    </span>
                  </div>

                  <div>
                    <small>Validade</small>
                    <span>
                      {transaction.warranty_date
                        ? formatDate(transaction.warranty_date, 'dd/MM/yyyy')
                        : '-'}
                    </span>
                  </div>

                  <div>
                    {transaction.status_info ? (
                      <StatusBadge
                        background={transaction.status_info.background}
                        color={transaction.status_info.color}
                      >
                        {transaction.status_info.name}
                      </StatusBadge>
                    ) : (
                      transaction.status
                    )}
                  </div>

                  <div>
                    <Button
                      color="primary"
                      disabled={transaction.status !== 'paid'}
                      onClick={() => {
                        setSelectedTransaction(transaction.payment_ext);
                        setAreDeleting(true);
                      }}
                    >
                      Estornar
                    </Button>
                  </div>
                </TransactionItem>
              ))}
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        title="Estornar transação"
        confirmText="Estornar"
        cancelText="Cancelar"
        text="Tem certeza de que deseja estornar a transação? Essa ação não poderá ser desfeita!"
        onConfirm={handleDelete}
        isOpen={areDeleting}
        isLoading={deleteLoading}
        setIsOpen={() => setAreDeleting(!areDeleting)}
        isInverse
      />
    </>
  );
};

export default StudentPreview;
