import React, { useEffect, useRef, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { IconBaseProps } from 'react-icons';
import { MdErrorOutline } from 'react-icons/md';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface Props extends InputProps {
  name: string;
  label: string;
  icon?: React.ComponentType<IconBaseProps>;
  isPhone?: boolean;
}

const Input: React.FC<Props> = ({
  label,
  name,
  icon: Icon,
  disabled = false,
  isPhone = false,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [phoneMask, setPhoneMask] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value: string) {
        if (value) {
          let formattedValue = value.replace(/[^\d]/g, '');

          if (formattedValue.length < 11) {
            formattedValue = formattedValue.replace(
              /(\d{2})(\d{4})(\d{4})/,
              '($1) $2-$3',
            );
          } else {
            formattedValue = formattedValue.replace(
              /(\d{2})(\d{5})(\d{4})/,
              '($1) $2-$3',
            );
          }

          ref.setInputValue(formattedValue);
        }
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  const handleChangePhoneMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 14) {
      setPhoneMask('(99) 9999-99999');
    } else {
      setPhoneMask('(99) 99999-9999');
    }
  };

  return (
    <Container hasError={!!error} disabled={disabled}>
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}
          <ReactInputMask
            defaultValue={defaultValue}
            ref={inputRef}
            type="text"
            disabled={disabled}
            mask={isPhone ? phoneMask : mask}
            onChange={e => isPhone && handleChangePhoneMask(e)}
            {...rest}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default Input;
