import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BaseCSS } from 'styled-bootstrap-grid';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <Routes />

        <GlobalStyle />
        <BaseCSS />
      </AppProvider>
    </Router>
  );
};

export default App;
