import React from 'react';
import { Container } from 'styled-bootstrap-grid';

import Profile from './Profile';
import Notifications from './Notifications';
import Menu from './Menu';

import { Background, Cover } from './styles';

import logo from '../../assets/logo.png';

interface HeaderProps {
  showMenu?: boolean;
  cover?: string;
}

const Header: React.FC<HeaderProps> = ({ showMenu = true, cover }) => {
  return (
    <>
      <Background>
        <Container>
          <img src={logo} alt="Logo Educacione" />

          <Notifications />

          <Profile />
        </Container>
      </Background>

      {showMenu && <Menu hasMargin={!cover} />}

      {cover && <Cover url={cover} />}
    </>
  );
};

export default Header;
