import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  background: '#f9f5fA',
  backgroundCard: '#ffffff',
  hoverPercentage: 0.02,
  shadow: 'rgba(183, 192, 206, 0.4) 0px 5px 20px',
  backdrop: '#6b5be260',

  title: '#4d4d4d',
  text: '#595959',

  input: {
    background: '#fff',
    border: '1px solid #9e9e9e',
    text: '#5e5e5e',
    icon: '#757575',
    placeholder: '#919191',
  },

  colors: {
    primary: '#6b5be2',
    secondary: '#3ecc73',
    tertiary: '#012b60',

    info: '#0099FF',
    warning: '#e3ae04',
    success: '#71AA0D',
    danger: '#e33232',
  },
};

export const darkTheme: DefaultTheme = {
  background: '#2B272E',
  backgroundCard: '#1D1A1F',
  hoverPercentage: 0.1,
  shadow: 'rgba(6,11,21,0.4) 0px 5px 20px',
  backdrop: '#6b5be210',

  title: '#F1E9F5',
  text: '#969199',

  input: {
    background: '#141314',
    border: '1px solid transparent',
    text: '#F1E9F5',
    icon: '#646066',
    placeholder: '#646066',
  },

  colors: {
    primary: '#6b5be2',
    secondary: '#3ecc73',
    tertiary: '#012b60',

    info: '#0099FF',
    warning: '#e3ae04',
    success: '#71AA0D',
    danger: '#e33232',
  },
};
