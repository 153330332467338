import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

import { Background, Content } from './styles';

const Footer: React.FC = () => {
  return (
    <Background>
      <Container>
        <Content>
          <p>Educacione - todos os direitos reservados</p>
        </Content>
      </Container>
    </Background>
  );
};

export default Footer;
