import React, { useState, useEffect } from 'react';
import { IconBaseProps } from 'react-icons';
import { Container } from 'styled-bootstrap-grid';
import { MdMenu, MdDashboard } from 'react-icons/md';

import { useAuth } from '../../../hooks/auth';

import MenuItem from './MenuItem';
import MobileMenuItem from './MobileMenuItem';

import { Background, MobileButton, MobileMenu } from './styles';

interface MenuProps {
  hasMargin?: boolean;
}

interface MenuItemProps {
  title: string;
  link?: string;
  icon: React.ComponentType<IconBaseProps>;
  subitems?: Array<{
    title: string;
    link: string;
  }>;
  roles: string[];
}

const Menu: React.FC<MenuProps> = ({ hasMargin = true }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () =>
        setWindowWidth(window.innerWidth),
      );
    };
  }, []);

  const menuItems: MenuItemProps[] = [
    {
      title: 'Dashboard',
      link: '/',
      icon: MdDashboard,
      roles: [],
    },
    {
      title: 'Administrativo',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Alunos',
          link: '/alunos',
        },
      ],
      roles: ['administrator'],
    },
    {
      title: 'Plataforma',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Filmes',
          link: '/filmes',
        },
      ],
      roles: ['administrator'],
    },
    {
      title: 'Configurações',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Carreiras',
          link: '/carreiras',
        },
      ],
      roles: ['administrator'],
    },
  ];

  return (
    <Background active={active} hasMargin={hasMargin}>
      <Container>
        {windowWidth > 991 ? (
          <ul>
            {menuItems.map(item => {
              if (item.roles.length > 0 && !item.roles.includes(user.role)) {
                return null;
              }

              return (
                <MenuItem
                  key={item.title}
                  icon={item.icon}
                  title={item.title}
                  subitems={item.subitems}
                  link={item.link}
                />
              );
            })}
          </ul>
        ) : (
          <>
            <MobileButton type="button" onClick={() => setActive(!active)}>
              <MdMenu size={24} />
            </MobileButton>

            <MobileMenu active={active}>
              <ul>
                {menuItems.map(item => {
                  if (
                    item.roles.length > 0 &&
                    !item.roles.includes(user.role)
                  ) {
                    return null;
                  }

                  return (
                    <MobileMenuItem
                      key={item.title}
                      icon={item.icon}
                      title={item.title}
                      subitems={item.subitems}
                      link={item.link}
                    />
                  );
                })}
              </ul>
            </MobileMenu>
          </>
        )}
      </Container>
    </Background>
  );
};

export default Menu;
