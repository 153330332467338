import styled from 'styled-components';

interface StatusBadgeProps {
  color: string;
  background: string;
}

export const StatusBadge = styled.span<StatusBadgeProps>`
  cursor: default;
  background: ${props => props.background};
  color: ${props => props.color};
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 4px 8px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: baseline;
  border-radius: 4px;
`;

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  padding: 24px;

  & + div {
    margin-top: 16px;
  }

  div {
    display: flex;
    flex-direction: column;

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }

    small {
      color: ${({ theme }) => theme.title};
      font-weight: bold;
    }
  }
`;

export const Profile = styled.div`
  text-align: center;

  img {
    display: block;
    width: 152px;
    height: 152px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 24px;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    padding: 8px;
  }

  span {
    font-size: 12px;
  }
`;

export const DataBox = styled.div`
  position: relative;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadow};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;

  span {
    color: ${({ theme }) => theme.title};
    font-size: 32px;
    font-weight: bold;
  }
`;
