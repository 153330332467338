import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import ScrollToTop from './ScrollToTop';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

import Forgot from '../pages/ForgotPassword';
import Reset from '../pages/ResetPassword';
import Movie from '../pages/Movie';
import MovieRegistration from '../pages/Movie/MovieRegistration';
import Student from '../pages/Student';
import StudentPreview from '../pages/Student/StudentPreview';
import Career from '../pages/Career';

const Routes: React.FC = () => (
  <ScrollToTop>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <Route path="/alunos" exact component={Student} isPrivate />
      <Route
        path="/alunos/visualizar/:id"
        exact
        component={StudentPreview}
        isPrivate
      />

      <Route path="/carreiras" exact component={Career} isPrivate />

      <Route path="/filmes" exact component={Movie} isPrivate />
      <Route
        path="/filmes/cadastrar"
        exact
        component={MovieRegistration}
        isPrivate
      />

      <Route path="/esqueci-minha-senha" exact component={Forgot} />
      <Route path="/resetar-senha" exact component={Reset} />
    </Switch>
  </ScrollToTop>
);

export default Routes;
